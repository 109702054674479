<template>
	<div class="body_wrap">
		<div class="line"></div>
		<div style="background:#f5f5f5;">
			<section>
				<el-steps :active="active" finish-status="success" align-center>
					<el-step :title="orderEntry === 0 ? '查看购物车' : orderEntry * 1 === 1 ? '选择产品' : '选择商家'"></el-step>
					<el-step title="确认订单信息"></el-step>
					<el-step title="成功提交订单"></el-step>
				</el-steps>
				<Address @selected="selectAddress"/>
				<div class="product-list">
					<el-row class="product-header">
						<el-col :span="24">
							<span class="product-header-tip">产品清单</span>
						</el-col>
					</el-row>
					<el-row class="product-table-head">
						<el-col :span="12">
							<div>产品信息</div>
						</el-col>
						<el-col :span="4">
							<div>单价</div>
						</el-col>
						<el-col :span="4">
							<div>数量</div>
						</el-col>
						<el-col :span="4">
							<div>小计</div>
						</el-col>
					</el-row>
					<el-row class="product-body">
						<el-row class="product-shop">
							<el-col :span="24">
								<div class="product-shop-name" v-text="item.storeName"></div>
							</el-col>
						</el-row>
						<div class="product-items-wrap">
							<el-row class="product-item">
								<el-col :span="12">
									<el-row>
										<el-col :span="6">
											<img :src="item.logoUrl | imagePath" alt>
										</el-col>
										<el-col :span="18">
											<p :title="item.productName" class="product-name"
											   v-text="item.productName"></p>
										</el-col>
									</el-row>
								</el-col>
								<el-col :span="4">
									<div class="product-price">￥{{item.price}}</div>
								</el-col>
								<el-col :span="4">
									<div class="product-num" v-text="1"></div>
								</el-col>
								<el-col :span="4">
									<div
											class="product-total-price"
									>￥{{item.price}}
									</div>
								</el-col>
							</el-row>
						</div>
					</el-row>
					<el-row v-if="0">
						<div class="receipt">
							<el-row>
								<el-col :span="24">
									<el-checkbox v-model="checked">需要开票</el-checkbox>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col :span="24" :offset="1">
									<span class="receipt-label">抬头类型：</span>
									<el-radio v-model="radio" label="1">企业</el-radio>
									<el-radio v-model="radio" label="2">个人</el-radio>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col :span="24" :offset="1">
									<span class="receipt-label">发票类型：</span>
									<el-radio v-model="radio" label="1">增值税专用发票</el-radio>
									<el-radio v-model="radio" label="2">增值税普通发票</el-radio>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col :span="24" :offset="1">
									<span class="receipt-label">发票信息</span>
								</el-col>
							</el-row>
							<div v-show="radio === 1">
								<el-row :gutter="20">
									<el-col :span="24" :offset="1" class="flex align-center">
										<span class="receipt-label">识别码：</span>
										<el-input type="text" placeholder="请输入识别码"></el-input>
									</el-col>
								</el-row>
								<el-row :gutter="20">
									<el-col :span="24" :offset="1" class="flex align-center">
										<span class="receipt-label">注册地址：</span>
										<el-input type="text" placeholder="请输入注册地址"></el-input>
									</el-col>
								</el-row>
								<el-row :gutter="20">
									<el-col :span="24" :offset="1" class="flex align-center">
										<span class="receipt-label">注册电话：</span>
										<el-input type="text" placeholder="请输入注册电话"></el-input>
									</el-col>
								</el-row>
								<el-row :gutter="20">
									<el-col :span="24" :offset="1" class="flex align-center">
										<span class="receipt-label">开户银行：</span>
										<el-input type="text" placeholder="请输入开户银行"></el-input>
									</el-col>
								</el-row>
								<el-row :gutter="20">
									<el-col :span="24" :offset="1" class="flex align-center">
										<span class="receipt-label">银行账号：</span>
										<el-input type="text" placeholder="请输入银行账号"></el-input>
									</el-col>
								</el-row>
							</div>
							<div v-show="radio ==2">
								<el-row :gutter="20">
									<el-col :span="24" :offset="1" class="flex align-center">
										<span class="receipt-label">抬头名称：</span>
										<el-input type="text" placeholder="请输入抬头名称"></el-input>
									</el-col>
								</el-row>
							</div>
						</div>
					</el-row>
					<el-row class="product-footer">
						<p>给卖家留言：</p>
						<el-col :span="12">
							<el-input type="textarea" :rows="4" placeholder="请输入内容(0-100)"
							          v-model="param.remark"></el-input>
						</el-col>
					</el-row>
				</div>
				<el-row class="product-submit" :gutter="20">
					<el-col :span="10" :offset="10">
						共{{1}}件产品，&nbsp;&nbsp; 产品总价：￥{{price}} 订单总金额
						<span>￥{{price}}</span>
					</el-col>
					<el-col
							class="product-submit-btn"
							:span="4"
							style="cursor:pointer;"
							@click.native="submitOrder"
					>提交订单
					</el-col>
				</el-row>
				<el-row class="tip-warp">
					<el-col :span="24">温馨提示：</el-col>
					<el-col :span="24">
						<span class="zeng f12">增</span>
						提交订单并确认收货成功后，将同时赠送您
						<span class="jf">{{score}}积分</span> ，可用于孔雀有礼
						<a href="###">积分商城</a>礼品兑换。
					</el-col>
				</el-row>
			</section>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>

    import Address from '../.././order/address/index';
    import {mapActions, mapGetters, mapMutations} from 'vuex'

    export default {
        name: 'orderPrice',
        components: {
            Address
        },
        data () {
            return {
                item: {},//回显对象
                active: 1,
                radio: 1,
                checked: true,
                totalNumber: 0,
                totalPrice: 0,
                score: 0,
                storeList: [],
                price: "",
                inviteSn: "",
                param: {
                    billType: 0,
                    cartTTos: [],
                    headType: 0,
                    invoiceId: -1,
                    invoiceTitle: '',
                    number: 0,
                    remark: '',
                    userConsigneeId: '',
                }
            }
        },
        computed: {
            ...mapGetters (['orderEntry', 'cartItems', 'product', 'store'])
        },
        methods: {
            ...mapActions (['queryCartList']),
            ...mapMutations ({savePay: 'PAY_INFO'}),
            selectAddress (e) {
                const {id, selected} = e
                if (selected) this.param.userConsigneeId = id
                else this.param.userConsigneeId = ''
            },
            calcPrice () {
                let price = 0
                let total = 0
                this.cartItems.forEach (item => {
                    price += item.cartItemList.reduce ((p, c) => {
                        return p + (c.checked && c.productPrice * c.productQuantity)
                    }, 0)
                    total += item.cartItemList.reduce ((p, c) => {
                        return p + (c.checked ? 1 : 0)
                    }, 0)
                })
                this.totalNumber = total
                this.totalPrice = price
                this.score = Math.floor (this.totalPrice)
            },
            getMes () {
                var item = this.$route.params.item;
                var _this = this;
                _this.item = item;
                _this.price = item.price;
                _this.inviteSn = this.$route.params.inviteSn;
            },
            submitOrder () {
                if (this.param.userConsigneeId === '') {
                    this.$message ({
                        message: '请选择收货地址',
                        type: 'error'
                    })
                    return
                }
                const param = {
                    billType: 0,
                    headType: 1,
                    invoiceId: '',
                    invoiceTitle: '',
                    number: 1,
                    price: this.item.price,
                    remark: this.param.remark,
                    storeId: this.item.storeId,
                    sn: this.inviteSn,
                    userConsigneeId: this.param.userConsigneeId
                };
                this.$http.post ('tOrder/addOrder', param).then (res => {
                    const {code, data} = res.data
                    if (code * 1 === 0) {
                        this.queryCartList ()
                        this.savePay (data[0])
                        this.$message ({
                            type: 'success',
                            duration: 1000,
                            message: '下单成功',
                            onClose: () => {
                                const {subject} = data[0]
                                this.$router.push ({name: 'paystatus', params: {orderSn: subject}})
                            }
                        })
                    }
                })
            }
        },
        mounted () {
            this.getMes ();
        }
    }
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/common';

	@mixin icon($m) {
		$url: '../../assets/image/' + $m;
		@include bgImage($url);
	}

	section {
		max-width: 1200px;
		width: 100%;
		margin: 0 auto 0;
		padding-top: 20px;
		// overflow: hidden;
		clear: both;

		.product-list {
			margin-top: 20px;
			background-color: #fff;
			padding: 20px;
			box-sizing: border-box;

			.product-header {
				.product-header-tip {
					padding-left: 10px;
					border-left: 3px solid $color168;
				}
			}
			.product-table-head {
				margin: 10px 0;
				background-color: #f5f5f5;
				padding: 10px;
			}
			.product-body {
				border: 1px solid #e6e6e6;
				.product-shop {
					padding: 10px;
					background: #f5f5f5;
				}
				.product-items-wrap {
					border-bottom: 1px solid #e6e6e6;
					.product-item {
						margin: 20px;
						img {
							width: 89px;
							height: 89px;
						}
						.product-name {
							width: 220px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						p {
							font-size: 12px;
							color: #999;
							line-height: 23px;
							&.product-name {
								font-size: 15px;
								color: #333;
							}
						}
						.product-price {
							font-size: 15px;
							color: #333;
						}
						.product-num {
							font-size: 14px;
							color: #333;
						}
						.product-total-price {
							color: #ff0036;
							font-size: 15px;
						}
					}
				}
			}
			// 发票
			.receipt {
				padding: 20px;
				.el-row {
					margin-bottom: 10px;
					.receipt-label {
						font-size: 14px;
						color: #666;
						min-width: 90px;
						+ .el-input {
							max-width: 300px;
						}
					}
				}
			}
			.product-footer {
				box-sizing: border-box;
				p {
					margin: 20px 0;
				}
				.remark-num-wrap {
					position: relative;
					.remark-num {
						position: absolute;
						bottom: -55px;
						left: 18px;
					}
				}
			}
		}
		.product-submit {
			margin-top: 20px;
			background-color: #fff;
			margin: 20px 0;
			height: 50px;
			box-sizing: border-box;
			line-height: 50px;
			span {
				color: red;
			}
			.product-submit-btn {
				color: #fff;
				text-align: center;
				background-color: #d82229;
			}
		}
		.tip-warp {
			padding: 5px;
			font-size: 14px;
			.el-col {
				margin-bottom: 10px;
			}
			.zeng {
				width: 40px;
				padding: 2px 5px;
				display: inline-block;
				color: #fff;
				text-align: center;
				background-color: #d82229;
			}
			.jf {
				color: red;
			}
			a {
				color: #d82229;
			}
		}
	}
</style>
